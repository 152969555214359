























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import firebase from 'firebase'
import { AppModule, Hotel, Customer, hotelConverter, customerConverter } from '@/store/app'
import 'firebase/firestore';
import fs = firebase.firestore;
const db = firebase.firestore();
import QRCode from 'qrcode'

@Component({
  components: {
  },
})
export default class CheckInView extends Vue {
  private qrcode = '';
  private image = '';

  private get customer(){
    return AppModule.customer;
  }

  private get hotel() {
    return AppModule.hotel;
  }
  mounted() {
    db.collection("hotels").doc(this.$route.params.hotelId).withConverter(hotelConverter).get().then((hotel)=>{
      AppModule.setHotel(hotel.data()!);
      firebase.storage().ref().child(`/hotels/${hotel.id}/${hotel.id}`).getDownloadURL().then((v)=>{
        this.image = v;
      });
    });
    QRCode.toDataURL(`${location.origin}/manage/customer/${this.$route.params.id}`, (err: string, url: string)=>{
      this.qrcode = url;
    });
  }

  async generateQR(text: string) {
    try {
      console.log(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  }
}
